import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Bug Bounty Hunter",
          "SOC analyst",
          "Ethical Hacking",
          "RED team & Blue Team",
          "Cyber Security Analyst"
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
